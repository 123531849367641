// Here you can add other styles
// change background
.wrapper {
  background-color: #F4FAF9 !important;
}
.hamburgerButtonIcons {
  color: #22A18A;
  position:fixed;
}

.sidebar-toggler::before{
  background-image: var(--cui-sidebar-toggler-indicator-hover);
}
.wrapper {
  img {

  }
}
