// Here you can add other styles
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1440px;
$breakpoint-laptop-m: 1840px;

.imgForm {
  cursor: grab;
  position: fixed;
  top: 0;
  display: inline-block;
  transition-duration: 800ms;
  height: 100%;
  min-height: 850px;
  max-height: 1024px;

  width: 100%;
  max-width: 624px;

  @media (min-width: $breakpoint-laptop-m) {
    border-radius: 15px;
    margin-top: 30px;
    height: calc(100vh - 60px);
  }
  @media (max-height: 850px) {
    position: relative;
  }

  @media (max-width: $breakpoint-laptop - 1) {
    min-height: 450px;
  }
}
.textLogoBaladeParfume {
  cursor: grab;
  display: flex;
  align-self: center;
  width: 168px;
  margin: 7.81vh auto auto;
  @media (max-height: 1024px) {
    margin: 2.41vh auto auto;
  }
}
.titreRegister {
  font-weight: bold;
  line-height: 1.3;
  color: #1c1c1c;
  font-size: 2.5rem;
  font-family: 'Amiko', serif;
  margin: 8.59375vh 0 0;
}
.textOverProgressBar {
  font-family: 'Mada', serif;
  font-size: 1rem;
  color: #1c1c1c;
}

.containerImageForm {
  width: 100%;
  height: 100%;
  max-width: 624px;
  /*  @media (max-width: $breakpoint-laptop - 1) {
    display: none;
  } */
}
.containerRegister {
  margin: 0 30px;
  .errorMessage {
    float: left;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 6px;
  }
  @media (min-width: $breakpoint-laptop-m) {
    margin: 0 0 0 136px;
  }
}
.containerProgressBar {
  position: relative;
  width: calc(100% - 100px); // 100PX is countStep
  height: 20px;
}
.containerInput {
  margin-top: 64px;
}

.login-container {
  background-color: white !important;
}
.login-form-container {
  display: flex;
  align-items: center;

  height: 100vh;
  @media (min-width: $breakpoint-laptop-m) {
    justify-content: center;
  }
  @media (max-width: $breakpoint-laptop - 1) {
    flex-direction: column;
  }
}

.barBackground {
  background-color: #efefef;
  border-radius: 8px;
  height: 8px;
}
.barCompleted {
  background-color: #22a18a;
  border-radius: 8px;
  height: 8px;
}
.formInputLogin {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  //background: red;
  border-radius: 15px;

  @media (min-width: $breakpoint-laptop) {
    max-width: 816px;
  }

  @media (min-width: 768px) {
    padding: 30px;
  }
}

.containerRelative {
  position: relative;
  min-width: 220px;
  width: 100%;
}
.inputGrey {
  background-color: #efefef;
  border-radius: 8px;
  height: 56px;
  margin-bottom: 5px;
  padding-left: 50px;
  padding-right: 50px;
  width: calc(100% - 100px); // remove padding in length
  color: #39302c;
  @media screen and (max-width: 720px) {
    font-size: 12px;
    width: 100%;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #39302c;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder {
    color: #39302c;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #39302c;
  }
}
.imgInputIcon {
  position: absolute;
  background-color: #bde3dc;
  padding: 7px;
  border-radius: 4px;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
}
.iconRight {
  position: absolute;
  padding: 7px;
  border-radius: 4px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.relative {
  position: relative;
}
.selectIcon {
  align-items: center;
}

.buttonNext {
  background-color: #22a18a;
  color: white;
  float: right;
  border-radius: 8px;
  height: 48px;
  font-size: 19px;
  margin-bottom: 1vh;
  width: 120px;
  text-align: center;
}
.send {
  padding: 3px;
  background-color: #22a18a;
  color: white;
  float: right;
  border-radius: 8px;
  height: 28px;
  font-size: 19px;
  margin-bottom: 1vh;
  width: 120px;
  text-align: center;
  &:disabled {
    background-color: dimgrey;
    color: linen;
    opacity: 1;
  }
}
.sizeButtonLogin {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
}
.textBottom {
  @media (min-height: 900px) and (max-height: 1000px) {
  }
  width: 100%;
  text-align: center;
}
.textBottomLink {
  color: #22a18a;
  text-decoration: underline;
}
.containerLog {
  .swiper {
    overflow: visible;
    overflow-x: hidden;
  }
}
h5 {
  white-space: nowrap;
}
