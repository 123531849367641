

.sidebar-brand,.sidebar, .sidebar-nav,.sidebar-toggler {
  .icon:not(.icon-c-s):not(.icon-custom-size).icon-lg {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.25rem;
    margin-top: 5px;
    margin-left: 12px;
  }
    background-color: $nav-bg;
    border-radius: 0 10pX 10px 0px;
    overflow-x: hidden;
    .nav-link {
      font-size: 16px;
      font-family: 'Mada', serif;
      font-weight: bold;
      color: white;
    }
    .nav-icon {
      flex: none;
      background-color: white;
      padding: 12px;
      box-sizing: content-box;
      border-radius: 8px;
      margin-right: 15px;
      height: 20px;

      .sidebar-narrow-unfoldable {
        margin-right: 0;
      }
    }
  .nav-link.active {
    color : #1C1C1C !important;
    background-color: white;
    border-radius: 8px;
    padding-left:5px;
    margin:16px 14px 16px 14px;
    .nav-icon {
      background-color: #DAC1D2;
    }
  }
  .nav-group .nav-item .nav-link.active{
    margin:0;
  }
    .show {
        .nav-link{
            font-size: 14px;
            font-family: 'Mada', serif;
            color : #39302C;
        }
        .nav-group-toggle {
            font-weight: bold;
            font-size: 16px;
            color : #1C1C1C !important;

          padding-left: 1px;
        }
        .nav-item:hover{
            background-color: rgba(34,161,138, 0.33);
        }
    }
  .sidebar-nav .nav-group.show {
    background-color: white;
    border-radius: 8px;
    margin: 16px 14px 16px 14px;
    .nav-icon {
      background-color: #BDE3DC;
    }
  }
  /* Pour les navigateurs basés sur WebKit (comme Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 6px; /* Largeur de la barre de défilement */
  }

  ::-webkit-scrollbar-track {
    background-color: #22A18A; /* Couleur de fond de la piste de défilement */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffffff99; /* Couleur de la barre de défilement */
    border-radius: 3px; /* Coins arrondis de la barre de défilement */
  }

  /* Pour les navigateurs basés sur Mozilla (comme Firefox) */
  /* Note : Firefox n'autorise pas la personnalisation de la couleur de fond de la piste de défilement */
  /* Vous pouvez ignorer ces règles si vous ne ciblez pas Firefox */
  scrollbar-width: thin;
  scrollbar-color: #ffffff99 #22A18A;
}
.simplebar-content {
  overflow: hidden;
}
.sidebar-brand, .sidebar-nav {
}
html:not([dir=rtl]) {
    .sidebar-nav .nav-group-toggle::after {
    //   visibility: hidden;
    }

    .show {
      .sidebar-nav .nav-group-toggle::after {
        // background-image : url('../../public/icon/chevronIcon.png');
        //     background-image: url(/icon/chevronIcon.png);
      }
    }
  }
  .sidebar-nav .nav-group.show > .nav-group-toggle::after {
    // visibility: visible;
    background-image: url('../../public/icon/chevronIcon.png');

  }
.sidebar {
    // --cui-sidebar-nav-group-indicator : url('../../public/icon/chevronIcon.png');
    // --cui-sidebar-nav-group-indicator-hover : url('../../public/icon/chevronIcon.png');
}
.sidebar-brand {
  margin-top : 38px;
  margin-bottom : 38px;
  overflow: hidden;

}

.simplebar-mask {
    a {
        padding: 25px;
    }
}

.nav-group-items .nav-item .active::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 45px;
    background-color: #BDE3DC;
    border-radius: 50%;
    margin-right: 10px;
}
.nav-item {
    position: relative;
}
.nav-icon {
    margin-left: 10px !important;
}
.sidebar-narrow-unfoldable {
  .sidebar-brand-narrow {
    height: 62px;
  }
  .nav-icon {
    margin-left: -9px !important;
  }
  .nav-link.active {
    padding-left: 14px;
    margin: 16px 4px 16px 4px;
  }
  .nav-group.show{
    padding-left: 12px;
    margin: 16px 4px 16px 4px !important;
  }
}

