.containerContentForm {
  flex-direction: row;
}

.contentForm {
  justify-content: center;
}

.inputContentForm {
  background-color: #efefef;
  border-width: 0;
  border-radius: 8px;
}

.iconSize {
  height: 18;
  width: 18;
}

.textAreaContentForm {
  height: 140px;
  background-color: #efefef;
  border-radius: 8px;
}

.imageForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  border-radius: 8px;
  padding-left: 10px;
  width: 100%;
}

.my-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.my-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 192px;
  width: 47%;
  max-width: 47%;
  background-color: #f5eff3;
  border-radius: 20px;
  border: 3px dashed rgba(130, 46, 102, 1);
}

.my-block-inner {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.my-icon {
  width: 48px;
  height: 48px;
  align-self: center;
  background-color: #dac1d2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.my-icon img {
}

@media screen and (max-width: 1024px) {
  .my-title {
    font-size: 1em;
  }
  .my-text {
    font-size: 1em;
  }
}

@media all and (max-width: 600px) {
  .containerItemUser {
    // flex-direction: column;
  }
}

.my-title {
  /* Ajoutez les styles de votre choix pour le titre */
}

.my-text {
  /* Ajoutez les styles de votre choix pour le texte */
}

.iconTitleContainer {
  justify-content: 'center';
  align-items: 'center';
}

.validate {
  padding: 10;
  background-color: rgba(130, 46, 102, 1);
  color: '#fff';
  border-radius: 8;
}

.file-drop-zone {
  height: 192px;
  flex: 1;
  border: 3px dashed rgba(130, 46, 102, 1);
  background-color: #f5eff3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #999;
}

.article-link {
  display: none;
}

.hide-drop-zone {
  display: none;
}

.file-drop-zone.dragging {
  background-color: #f7f7f7;
  border-color: #888;
}
