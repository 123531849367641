 /*
Reset CSS v2.0.1 | Eric Meyer
License: none (public domain)
*/

/* Box sizing rules */
html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  /* Remove default margin */
  body,
  h1, h2, h3, h4, h5, h6,
  p, blockquote, pre,
  dl, dd, ol, ul,
  figure,
  hr,
  fieldset, legend {
    margin: 0;
    padding: 0;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }

  /* Remove the focus ring from buttons and links in Firefox */
  button::-moz-focus-inner,
  a::-moz-focus-inner {
      border: 0;
      padding: 0;
  }

  /* Remove the focus ring from forms in Firefox */
  input::-moz-focus-inner,
  textarea::-moz-focus-inner {
      border: 0;
      padding: 0;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  select,
  textarea {
    all: unset;
    font: inherit;
  }

  /* Remove the padding from select elements in IE11 */
  select {
      padding: 0;
  }

  /* Define line-height as normal for all elements */
  * {
    line-height: normal;
  }

  /* Correct the cursor style for disabled checkboxes and radio buttons */
  [disabled] {
    cursor: not-allowed;
  }

  /* Define the root font size */
  :root {
    font-size: 16px;
  }

  /* Define a better looking default horizontal rule */
  hr {
    size: 1px;
    border: none;
    border-top: 1px solid #ccc;
  }

